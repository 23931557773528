<template>
  <v-app scroll-off-screen="true">
    <TheHeader />
    <v-main class="app-layout">
      <v-container>
        <v-row>
          <v-col
              sm="10" offset-sm="1"
              md="8" offset-md="2"
          >
            <div v-if="loggedIn">
              <slot />
            </div>
            <div v-else>
              <LoginUser/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <TheFooter/>
  </v-app>
</template>

<script>
import TheFooter from "./TheFooter";
import TheHeader from "./TheHeader";
import LoginUser from "../views/LoginUser";
import { authComputed } from "../store/helpers";

export default {
  name: "Layout",
  components: {
    TheHeader,
    TheFooter,
    LoginUser,
  },
  computed: {
    ...authComputed
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap');
#app {
  font-family: 'Source Sans Pro', sans-serif;
}
html { overflow-y: auto }

.app-layout {
  margin-bottom: 55px;
}
</style>