import axios from 'axios';

const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

axios.defaults.withCredentials = false;

const HTTP = axios.create({
    xsrfCookieName: CSRF_COOKIE_NAME,
    xsrfHeaderName: CSRF_HEADER_NAME,
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://crm.vranjes.ru/api/v1/',

    headers: {
        "Content-Type": "application/json",
    }
});



export default HTTP;
