<template>
  <v-form @submit.prevent="login">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <h1 class="display-1 font-weight-bold mb-3">
          Вход
        </h1></v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              v-model="username"
              label="Имя пользователя"
              outlined
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              v-model="password"
              label="Пароль"
              outlined
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-snackbar
              :timeout="2000"
              :value=errors
              centered
              absolute

              elevation="24"
              color="yellow"
              dark
          >
            <div v-for="(error, index) in errors" :key="index" class="black--text text-center">{{ error }}</div>
          </v-snackbar>
          <v-btn
              type="submit" name="button"
              color="primary"
              dark
              x-large
          >Вход</v-btn>

        </v-col>
      </v-row>
    </v-container>
  </v-form>

</template>

<script>
export default {
  name: "LoginUser",
  data () {
    return {
      username: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Обязательное поле.',
      },
      errors: null,
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login',{
        username: this.username,
        password: this.password,
      })
      .then(() => {
        this.$router.push({name: 'home'})
      })
      .catch(err => {
        var warning = 'Что-то пошло не так.'
        if (err == 'Error: Request failed with status code 400') {
          warning = 'Проверьте логин и пароль.'
        }
        this.errors = err.response.data.non_field_errors ||
            [`${warning}`, 'Непредвиденная ошибка. Обратитесь к администратору.']
      })
    }
  }
}
</script>

<style scoped>

</style>