import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "goods" */ '../views/Home.vue'),
  },
  {
    path: '/notificates/:days',
    name: 'notificates',
    component: () => import(/* webpackChunkName: "goods" */ '../views/NotificateList.vue')
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import(/* webpackChunkName: "goods" */ '../views/GoodList.vue')
  },
  {
    path: '/goods/:id',
    name: 'good-detail',
    component: () => import(/* webpackChunkName: "good" */ '../views/GoodDetail.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/ClientList.vue')
  },
  {
    path: '/clients/create',
    name: 'client-create',
    component: () => import(/* webpackChunkName: "client-create" */ '../views/ClientForm.vue')
  },
  {
    path: '/clients/:id/create_bill',
    name: 'client-create-bill',
    component: () => import(/* webpackChunkName: "client-create-bill" */ '../views/SaleForm.vue')
  },
  {
    path: '/clients/:id',
    name: 'client-detail',
    component: () => import(/* webpackChunkName: "client-detail" */ '../views/ClientDetail.vue')
  },
  {
    path: '/clients/:id/edit',
    name: 'client-edit',
    component: () => import(/* webpackChunkName: "client-edit" */ '../views/ClientForm.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginUser.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
