<template>
  <v-footer
      fixed
      padless
      light
      width="100%"
      class="primary text-center"
  >
    <v-card-text class="white--text">
      {{ new Date().getFullYear() }} &copy; <a href="https://aromatitaly.ru" class="footer_link">Aromat Italy</a>
      | Produced by <a href="https://migen.ru" class="footer_link">migen.ru</a>
    </v-card-text>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
}
</script>

<style scoped>
.footer_link{
  text-decoration: none;
  font-weight: bold;
  color: white;
}
</style>