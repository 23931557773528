<template>
  <div>
    <v-app-bar
        dark
        color="primary"
        max-height="70px"
    >

      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-divider
          vertical
      ></v-divider>

      <v-btn
          icon
          @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title class="ms-5">{{ main.title }}</v-toolbar-title>
      <v-spacer/>
      <v-divider
          vertical
      ></v-divider>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-divider
          vertical
      ></v-divider>
      <v-menu
          left
          bottom
      >
        <template v-slot:activator="{ on, attrs }" >
          <v-btn

              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list  v-if="loggedIn">
          <v-list-item @click="logout">
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        app
    >
      <v-list>
        <v-list-item>
          <v-row  justify="center">
            <a
                @click="home"
                to="/">
              <img
                  src="../assets/logo.svg"
                  width="180px" >
            </a>
          </v-row>
        </v-list-item>
        <v-list-item
            v-for="item in items"
            :key="item.text"
            router :to="item.link"
            class="my-4"
        >
          <v-list-item-action>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>


</template>

<script>
import { authComputed } from "../store/helpers";

export default {
  name: "TheHeader",
  data: () => ({
    items: [
      { text: 'Главная', icon: 'mdi-home', link:'/', },
      { text: 'Товар', icon: 'mdi-folder-open', link:'/goods', },
      { text: 'Клиенты', icon: 'mdi-account', link:'/clients', },
      { text: 'Выход', icon: 'mdi-logout', link:'/logout', },
    ],
    main: {
      title: "Aromat Italy",
    },
    drawer: false,
    prevRoute: null
  }),
  methods: {
    home() {
      this.$router.push({name: 'home'});
      this.drawer = !this.drawer;
    },
    logout() {
      this.$store.dispatch('logout')
      console.log('Logged out!')
    }
  },
  computed: {
    ...authComputed
  }
}
</script>