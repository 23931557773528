import Vue from 'vue'
import Vuex from 'vuex'
import HTTP from '../api/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clients: [],
    goods: [],
    user: null,
    username: null,
  },

  mutations: {
    SET_USER_DATA (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      HTTP.defaults.headers.common['Authorization'] = `token ${userData.key}`
    },
    SET_USERNAME (state, payload) {
      state.username = payload
    },
    CLEAR_USER_DATA () {
      // state.user = null
      localStorage.removeItem('user')
      location.reload()
      // HTTP.defaults.headers.common['Authorization'] = null
    },

    setClients: (state, clients) => {
      state.clients = clients;
    }
  },

  actions: {
    GET_CLIENTS_FROM_API({commit}) {
      return HTTP('clients/all', {
        method: "GET"
      }).then((clients) => {
        commit('setClients', clients.data);
        return clients;
      }).catch((error) => {console.log(error)})
      },

    login({commit}, credentials) {
      return HTTP
          .post('rest-auth/login/', credentials)
          .then(({ data }) => {
            commit('SET_USER_DATA', data)
          })
    },
    logout ({ commit }) {
      commit('CLEAR_USER_DATA')
    },
    username ({ commit }) {
      return HTTP
          .get('rest-auth/login/')
          .then(({ data }) => {
            commit('SET_USERNAME', data['username'])
          })
    }
    },

  getters: {
    CLIENTS(state) {
      return state.clients;
    },
    loggedIn(state) {
      return !!state.user
    },
    username(state) {
      return state.username
    }
  },

  modules: {},
})
